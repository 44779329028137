import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ConfigScreen.module.css';

function CarPack(props) {
  const navigate = useNavigate();

  const next = () => {
    navigate('/create-server/step-launch/');
  }
  
  return (
    <>
      <div className={styles.createContent}>
        <h2>Type server name</h2>
        <input type="text" defaultValue="Server name" onChange={(e) => props.setServerName(e.target.value)} />
        <input type="button" value="Next" onClick={next} />
      </div>
    </>
  );
}

export default CarPack;