import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  API_URL,
  AC_MANAGER,
  AC_IP,
  API_KEY,
  acServerApiHeader
} from '../../../config';
import { Clipboard } from '../../../extras';
import { acLauncher } from '../../../acLauncher';
import { getCarPacks } from './LaunchScreenApi';
import { useNavigate } from 'react-router-dom';
import styles from './LaunchScreen.module.css';

function LaunchScreen(props) {
  const navigate = useNavigate();
  const [link, setLink] = useState(null);
  const [isServerRequested, setIsServerRequested] = useState(false);
  const [port, setPort] = useState(0);
  const [language, setLanguage] = useState('en');
  const [translation, setTranslation] = useState({
    "launchServerButton": {
      "en": "Launch",
      "pl": "Uruchom"
    }
  });

  console.log(props.serverTrack);
  console.log(props.serverTrackConfig);

  document.title = "G8rage - Make A New Server | Launch";

  async function launchServer() {

    setIsServerRequested(true);

    try {
      const data = {
        track: props.serverTrack,
        trackConfig: props.serverTrackConfig,
        carPack: props.serverCarPack,
        serverName: props.serverName
      };

      const response = await axios.post(`${API_URL}server/create/`, JSON.stringify(data), acServerApiHeader);

      if (response.status === 200) {
        setPort(response.data[0].port);
        setLink(`acmanager://race/online/join?query=race/online/join&ip=${AC_IP}&httpport=`+response.data[0].port);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const makeNewServer = () => {
    navigate('/create-server/step-track/');
  }
  
  return (
    <>
      <div className={styles.createContent}>
        {link ? (
          <>
            <h2>Started</h2>
            <button
              onClick={() => {acLauncher(port)}}>
              Join now
            </button>
          </>
        ) : (
          <h2>Ready to launch</h2>
        )}
        {link ? (
          null
        ) : (
          <>
            {isServerRequested ? (
              <p>Launching...</p>
            ) : (
              <input
                className={styles.btnLaunch}
                type="button"
                value={translation.launchServerButton[language]}
                onClick={launchServer} />
            )}
          </>
        )};
        <a className={styles.joinLink}>
          {link ? (
            <>
              {link}
              <button
                className={styles.joinLinkBtnCopy}
                onClick={() => {Clipboard(link)}}>
                <span className={`${styles.joinLinkBtnCopySpan} material-symbols-outlined`}>
                  content_copy
                </span>
              </button>
            </>
          ) : null}
        </a>
        <input
          type="button"
          value="Make A New One"
          onClick={makeNewServer} />
      </div>
    </>
  );
}

export default LaunchScreen;