import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { title, API_URL, apiHeader } from '../../config';

function LoginForm(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSuccess, setIsSuccess] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  document.title = title + ' - Sing up';

  const signIn = () => {
    navigate('/login');
  }

  async function signUp() {
    try {
      const data = {
        email: email,
        password: password
      };
      const response = await axios.post(`${API_URL}create/account`, JSON.stringify(data), apiHeader);

      if (response.status === 200) {
        //props.setCookie('token', response.data.auth.token, { maxAge: response.data.auth.exp });
        //props.setAuthToken(response.data.auth.token);
        //props.setCookie('email', response.data.auth.email, { maxAge: response.data.auth.exp });
        //props.setAuthEmail(response.data.auth.email);
        setIsSuccess(response.data.account.isSuccess);
        setMessage(response.data.account.message);
        console.log(response.data);
        //navigate('/');
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <>
      <h1>Create New Account</h1>
      <div>
        <label>
          E-mail
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Password
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
      </div>
      <div>
        <input
          type="button"
          value="Already have an Account? Sign in"
          onClick={(e) => signIn()}
        />
      </div>
      {isSuccess != null ? (
        <div>
          <p>{message}</p>
        </div>
      ) : (null)}
      <div>
        <input
          type="button"
          value="Sign Up"
          onClick={(e) => signUp()}
        />
      </div>
    </>
  );
}

export default LoginForm;