import React, { useState, useEffect } from 'react';
import styles from './carPack.module.css'; 

function CarPack(props) {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(props.content.name);
  });

  return (
    <>
      {name ? (
        <h3 className={styles.name}>
          {name}
        </h3>
      ) : (
        <div className={styles.skelet}></div>
      )}
    </>
  )
}

export default CarPack;