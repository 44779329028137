import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import navStyle from './Navigation.module.css';

function Navigation(props) {
  const navigate = useNavigate();

  document.title = "G8rage";

  const logout = () => {
    props.removeCookie('token');
    props.removeCookie('email');
    props.setAuthToken(null);
    props.setAuthEmail(null);
    window.location.href = '/login';
  }

  useEffect(() => {
      if (props.authToken == null) {
        navigate('/login');
      }
  }, [props.cookie.token]);
  
  return (
    <div className={navStyle.nav}>
      <p>User: {props.cookie.email ?? props.authEmail}</p>
      <input
        type="button"
        value="Logout"
        onClick={(e) => logout()}
      />
      <h1 onClick={() => navigate('/')}>G8rage</h1>
      <input
        type="button"
        value="Create Server"
        onClick={() => navigate('/create-server/')}/>
      <input
        type="button"
        value="Create Track"
        onClick={() => navigate('/setup-track/')}/>
      <input
        type="button"
        value="Create Car Pack"
        onClick={() => navigate('/setup-car-pack/')}/>
      <input
        type="button"
        value="Create Car"
        onClick={() => navigate('/setup-car/')}/>
    </div>
  );
}

export default Navigation;