import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { title, API_URL, apiHeader } from '../../config';

import loginFormStyle from './loginForm.module.css';

function LoginForm(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSuccess, setIsSuccess] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  document.title = title + ' - Sing in';

  const signUp = () => {
    navigate('/signup');
  }

  async function loginIn() {
    try {
      const data = {
        email: email,
        password: password
      };
      const response = await axios.post(`${API_URL}auth`, JSON.stringify(data), apiHeader);

      if (response.status === 200) {
        setIsSuccess(response.data.auth.isSuccess);
        setMessage(response.data.auth.message);
        if (response.data.auth.isSuccess) {
          props.setCookie('token', response.data.auth.token, { maxAge: response.data.auth.exp });
          props.setCookie('email', response.data.auth.email, { maxAge: response.data.auth.exp });
          window.location.href = '/';
        }
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    if (props.cookie.token != null) {
      props.setAuthToken(props.cookie.token);
      navigate('/');
    }
  }, [props.cookie.token]);

  return (
    <>
    <div></div>
    <div className={loginFormStyle.page}>
      <div className={loginFormStyle.container}>
        <div className={loginFormStyle.loginForm}>
          <h1>Sign in to <b>G8RAGE</b></h1>
          <div>
            <label>
              E-mail
              <input
                className={loginFormStyle.input}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label>
              Password
              <input
                className={loginFormStyle.input}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
          </div>
          <div>
            <input
              className={loginFormStyle.redirect}
              type="button"
              value="Don't have an Account? Sign up"
              onClick={(e) => signUp()}
            />
          </div>
          {isSuccess == false ? (
            <div>
              <p>{message}</p>
            </div>
          ) : (null)}
          <input
            className={loginFormStyle.cta}
            type="button"
            value="Login"
            onClick={(e) => loginIn()}
          />
          <div className={loginFormStyle.links}>
            <a href="https://discord.com/invite/arFt6JHwzm" target="_blank">Discord</a>
            <div className={loginFormStyle.separator}></div>
            <a href="https://patreon.com/g8rage" target="_blank">Patreon</a>
          </div>
        </div>
      </div>
      <div className={loginFormStyle.container}>
        <img className={loginFormStyle.image} src="background.jpg" alt="Description of the image" />
      </div>
    </div>
    </>
  );
}

export default LoginForm;