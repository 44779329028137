import React, { useEffect, useState } from 'react';
import styles from './modalDownload.module.css'; 

function ModalDownload(props) {
  const {isModalDownloadVisible, closeModalDownload, content} = props;
  const {track, carPack} = content;
  const [type, setType] = useState('');
  let [link, setLink] = useState([]);

  useEffect(() => {
    props.content.type === 'server' && setType('server');
    props.content.type === 'track' && setType('track');
    props.content.type === 'carPack' && setType('carPack');

    if (type === 'server') {
      setLink(new Map([
        ['track', `https://content.t8lents.com/track/${props.content.track.slug}.zip`],
        ['carPack', `https://content.t8lents.com/car/${props.content.carPack.slug}.zip`],
      ]));
    }

    if (type === 'track') {
      setLink(new Map([
        ['track', `https://content.t8lents.com/track/${props.content.slug}.zip`]
      ]));
    }

    if (type === 'carPack') {
      setLink(new Map([
        ['carPack', `https://content.t8lents.com/car/${props.content.slug}.zip`]
      ]));
    }

  }, [type]);

  return (
    <div
      className={styles.modal}
      style={{ display: isModalDownloadVisible ? 'flex' : 'none' }}>
      <div
        className={styles.container}>
        {type === 'server' && (
          <h3
            className={styles.title}>
            {props.content.track.name}
          </h3>
        )}
        {type === 'track' && (
          <h3
            className={styles.title}>
            {props.content.name}
          </h3>
        )}
        {type === 'carPack' && (
          <h3
            className={styles.title}>
            {props.content.name}
          </h3>
        )}
        <div
          className={styles.block}>
          {type === 'server' && (
            <a
              className={styles.link}
              href={link instanceof Map ? link.get('track') : null}>
              <p
                className={styles.link}>
                Download Track "{props.content.track.name}"
              </p>
            </a>
          )}
        </div>
        <div
          className={styles.block}>
          {type === 'server' && (
          <a
            className={styles.link}
            href={link instanceof Map ? link.get('carPack') : null}>
            <p
              className={styles.link}>
              Download Car Pack "{props.content.carPack.name}"
            </p>
          </a>
          )}
          {type === 'track' && (
          <a
            className={styles.link}
            href={link instanceof Map ? link.get('track') : null}>
            <p
              className={styles.link}>
              Download "{props.content.name}"
            </p>
          </a>
          )}
          {type === 'carPack' && (
          <a
            className={styles.link}
            href={link instanceof Map ? link.get('carPack') : null}>
            <p
              className={styles.link}>
              Download "{props.content.name}"
            </p>
          </a>
          )}
        </div>
        <input
          className={styles.btnClose}
          type="button"
          value="Close"
          onClick={closeModalDownload}/>
      </div>
    </div>
  );
}

export default ModalDownload;