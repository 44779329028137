import React from 'react';
import styles from '../online.module.css';

function Player({ index, content }) {
  return (
    <div className={styles.blockDriver1} key={index}>
      <div className={styles.blockDriverImage}></div>
      <div>
        <div className={styles.blockDriver3}>{content}</div>
        <div className={styles.blockDriverTrack}>{content}</div>
      </div>
    </div>
  );
}

export default Player;