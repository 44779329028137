import axios from 'axios';
import { API_URL } from '../../config';

export const getTracks = async () => {
  try {
    const response = await axios.get(`${API_URL}get/track/0/2023-10-17-12-04-30`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Request failed with status code: ${response.status}`);
    }
  } catch (error) {
    throw new Error(`API Request Error: ${error.message}`);
  }
};