import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MainCallToAction.module.css';

function MainCallToAction() {
  const navigate = useNavigate();

  const createServer = () => {
    navigate('/create-server/step-track');
  }
  
  return (
    <>
      <input type="button" value="Create Server" onClick={() => createServer()}/>
    </>
  );
}

export default MainCallToAction;