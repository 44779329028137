import React, { Component } from 'react';
import styles from './block/block.module.css'; 

class Search extends Component {
  render() {
    return (
      <div className={styles.searchContainer}>
        <div className={styles.searchContainerAuthor}><div className={styles.author2}></div></div>
      </div>
    );
  }
}

export default Search;