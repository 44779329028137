import React, { useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { getCurrentDateTime } from '../../utils/dateTimeUtils';
import { paginationController } from '../../utils/paginationUtils';
import axios from 'axios';
import Block from './block/Block.js';
import styles from './block/block.module.css';
import Search from './Search.js';
import Footer from './footer/Footer';

function Feed() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoMoreToLoad, setIsNoMoreToLoad] = useState(false);
  const currentDateTime = getCurrentDateTime();

  const handleScroll = () => {
    if (paginationController()) {
      if (!isLoading) {
        setPage(prevPage => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fetchData = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.get(`${API_URL}get/feed/${page}/${currentDateTime}`);
      setData([...data, ...response.data]);
      if (response.data.length < 10) {
        setIsNoMoreToLoad(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);
  return (
    <>
      <Search />
      <div className={styles.server}>
        <div className={styles.serverList}>
        {data.map((item, index) => (
            <Block key={index} content={item} aa="aaa"/>
          ))}
        </div>
      </div>
      <Footer info={isNoMoreToLoad ? 'That\'s all. This is where it all started :)' : 'Loading...'}/>
    </>
  );
}

export default Feed;