import React from 'react';
import styles from './downloadButton.module.css'; 

function FeedBlockDownloadButton({ toggleModalDownload }) {
  return (
    <div
      className={styles.btn}
      onClick={() => toggleModalDownload()}>
      <span
        className="material-symbols-outlined">
        download
      </span>
    </div>
  );
}

export default FeedBlockDownloadButton;