import axios from 'axios';
import { API_URL } from '../../config';

export const getCarPacks = async (token) => {
  try {
    const response = await axios.post(`${API_URL}users/car-packs`, JSON.stringify(token));
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Request failed with status code: ${response.status}`);
    }
  } catch (error) {
    throw new Error(`API Request Error: ${error.message}`);
  }
};