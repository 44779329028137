import React, { useState, useEffect } from 'react';
import {
  API_URL,
  acServerApiHeader
} from '../../config';
import styles from './SetupCar.module.css';
import axios from 'axios';

function SetupCar() {
  const [carBrand, setCarBrand] = useState('');
  const [carModel, setCarModel] = useState('');
  const [carDirectory, setCarDirectory] = useState('');
  const [carSkinName1, setCarSkinName1] = useState('');
  const [carSkinName2, setCarSkinName2] = useState('');
  const [carSkinDirectory1, setCarSkinDirectory1] = useState('');
  const [carSkinDirectory2, setCarSkinDirectory2] = useState('');

  document.title = "G8rage - Create Car";

  async function createCar() {
    try {
      const data = {
        carBrand: carBrand,
        carModel: carModel,
        carDirectory: carDirectory,
        carSkinName1: carSkinName1,
        carSkinDirectory1: carSkinDirectory1,
        carSkinName2: carSkinName2,
        carSkinDirectory2: carSkinDirectory2
      };

      console.log('Prepared:', data);
      const response = await axios.post(`${API_URL}create/car/`, JSON.stringify(data), acServerApiHeader);

      if (response.status === 200) {
        setCarBrand('');
        setCarModel('');
        setCarDirectory('');
        setCarSkinName1('');
        setCarSkinName2('');
        setCarSkinDirectory1('');
        setCarSkinDirectory2('');
        console.log('Recived: ', response.data);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  
  return (
    <div class={styles.content}>
      <br></br><label>Car Brand</label>
      <input type="text" value={carBrand} placeholder="BMW" onChange={(e) => setCarBrand(e.target.value)}/>
      <br></br><label>Car Model</label>
      <input type="text" value={carModel} placeholder="E30 Drift" onChange={(e) => setCarModel(e.target.value)}/>
      <br></br><label>Car Directory</label>
      <input type="text" value={carDirectory} placeholder="bmw_m3_e30_drift" onChange={(e) => setCarDirectory(e.target.value)}/>
      <a>Do not enter full path like <s>C:\Program Files (x86)\Steam\steamapps\common\assettocorsa\content\cars\bmw_m3_e30_drift</s> but instead just <b>bmw_m3_e30_drift</b> (the directory with the car)</a>
      <br></br><p>Enter at least the first skin name and directory to save.</p>
      <br></br><label>Car Skin 1 Name</label>
      <input type="text" value={carSkinName1} placeholder="DGFX Vinyl" onChange={(e) => setCarSkinName1(e.target.value)}/>
      <br></br><label>Car Skin 1 Directory</label>
      <input type="text" value={carSkinDirectory1} placeholder="DGFX_Vinyl" onChange={(e) => setCarSkinDirectory1(e.target.value)}/>
      <a>Do not enter full path like <s>C:\Program Files (x86)\Steam\steamapps\common\assettocorsa\content\cars\bmw_m3_e30_drift\skins\DGFX_Vinyl</s> but instead just <b>DGFX_Vinyl</b> (the directory with the skin)</a>
      <br></br><label>Car Skin 2 Name</label>
      <input type="text" value={carSkinName2} placeholder="Nitto Red" onChange={(e) => setCarSkinName2(e.target.value)}/>
      <br></br><label>Car Skin 2 Directory</label>
      <input type="text" value={carSkinDirectory2} placeholder="Nitto_Red" onChange={(e) => setCarSkinDirectory2(e.target.value)}/>
      <br></br><input type="button" value="Save" onClick={() => createCar()}/>
    </div>
  );
}

export default SetupCar;