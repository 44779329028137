import React, { useState, useEffect } from 'react';
import { getTracks } from './TrackScreenApi';
import { useNavigate } from 'react-router-dom';
import styles from './TrackScreen.module.css';

import List from '../../block/BlockList.js';

function TrackScreen(props) {
  const navigate = useNavigate();
  const [tracks, setTracks] = useState([]);
  const [track, setTrack] = useState(0);
  const [selectedTrack, setSelectedTrack] = useState(0);
  const [serverTrackConfig, setServerTrackConfig] = useState(0);

  document.title = "G8rage - Create Server";

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const data = await getTracks();
        setTracks(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchTracks();
  }, []);

  function chooseTrack(id, layoutId = null) {
    let key = null;
    let layoutKey = null;
    for (const index in tracks) {
      if (tracks[index].id === id) {
        key = index;
        break;
      }
    }
    if (layoutId != null) {
    for (const index in tracks[key].layout) {
      if (tracks[key].layout[index].id === layoutId) {
        layoutKey = index;
        break;
      }
    }
    }
    //console.log(id);
    //console.log();
    //console.log(layoutKey);
    setTrack(tracks[key]);
    console.log(tracks[key]);
    props.setServerTrack(tracks[key]);
    if (layoutId != null) {
    props.setServerTrackConfig(tracks[key].layout[layoutKey]);
    }

    //console.log(props.serverTrack);
    //console.log(serverTrackConfig);
    navigate('/create-server/step-car-pack/');
  }
  
  return (
    <>
      <h2>Select Track</h2>
      <div className={styles.container}>
        {tracks.map((item, index) => (
          <div className={styles.track} key={index}>
            <div className={styles.content}>
              <p>{item.name}</p>
            </div>
            <div className={styles.layouts}>
              {typeof item.layout === 'undefined' ? (
                <input
                  className={`${
                    selectedTrack === item.id ? styles.active : null
                  }`}
                  type="button"
                  value={item.name}
                  onClick={() => chooseTrack(item.id)}
                />
              ) : (
                <>
                  {item.layout.map((layoutItem, layoutIndex) => (
                    <input
                      key={layoutIndex}
                      className={`${
                        selectedTrack === item.id ? styles.active : null
                      }`}
                      type="button"
                      value={layoutItem.name}
                      onClick={() => chooseTrack(item.id, layoutItem.id)}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default TrackScreen;