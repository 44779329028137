import React, { useEffect, useState } from 'react';
import { API_URL, AC_IP } from '../../config';
import axios from 'axios';
import styles from './online.module.css';
import Player from './player/Player';
import firebase from '../../firebase.js';
import { getDatabase, ref, onValue } from "firebase/database";

function Online() {
  const [config, setConfig] = useState([]);
  const [levels, setLevels] = useState([]);
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const [players, setPlayers] = useState([]);

 

  useEffect(() => {
    const updateRealtimeData = (config, data, users) => {
      if (typeof config.tier !== 'undefined') {
        let length = config.tier.length;
        setLevels([ 6, 5, 4, 3, 2, 1, 0, -1 ]);
        const playersData = {
          6: [], 5: [], 4: [], 3: [], 2: [], 1: [], 0: []
        };
        //for(var l = (length - 1); l >= 0; l--) {
          //console.log(111);
          //console.log(data);
        for(var l = 0; l < length; l++) {
          //playersData[l].tier = config.tier[0];
          for(var i = 0; i < data.length; i++) {
            if (data[i]['available']) {
              for(var j = 0; j < data[i]['players'].length; j++) {
                let tiers = users.tiers;
                if (typeof tiers != 'undefined') {
                  //console.log(l + ' ' + levels[l]);
                  //console.log(tiers);
                  for (var k = 0; k < tiers[levels[l]][1].length; k++) {
                    if (data[i]['players'][j]['guid'] == tiers[levels[l]][1][k]) {
                      data[i]['players'][j]['tier'] = levels[l].toString();
                      playersData[levels[l]].push(data[i]['players'][j]['username']);
                    }
                  }
                }
              }
              if (l === 6) {
                for (var j = 0; j < data[i]['players'].length; j++) {
                  if (data[i]['players'][j]['tier'] === undefined) {
                    playersData[6].push(data[i]['players'][j]['username']);
                  }
                }
              }
            } 
          }
          //console.log(playersData);
          setPlayers(playersData);
        }
      }
    }
    const fetchData = async () => {
      try {
        const responseConfigData = await axios.get(`${API_URL}config`);
        if (JSON.stringify(config) != JSON.stringify(responseConfigData.data)) {
          setConfig(responseConfigData.data);
        }
  
        const responseUsersData = await axios.get(`${API_URL}users`);
        if (JSON.stringify(users) != JSON.stringify(responseUsersData.data)) {
          setUsers(responseUsersData.data);
        }

        const responsePlayerData = await axios.get(`http://${AC_IP}:8000/servers`);
        //if (JSON.stringify(player) != JSON.stringify(responsePlayerData.data)) {
          setData(responsePlayerData.data);
        //}

        
        //if ((config != undefined) && (users != undefined)) {
          updateRealtimeData(config, data, users);
        //}

        console.log(players);
        
      } catch (error) {}
    };

    fetchData();

    //const db = getDatabase();
    //const starCountRef = ref(db, 'servers');
    /*onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setData(data);
      
      if ((config != undefined) && (users != undefined)) {
        updateRealtimeData(config, data, users);
      }
    });*/
  }, [config, users, data, players]);

  /*
  <p className={styles.ty}>Drifter Tier 3 - {config.tier[4].name}</p>
          {players.level3  ? (
            players.level3.map((item, index) => (
              <Player key={index} content={item}/>
            ))
          ) : null}
          <p className={styles.ty}>{config.tier[0].name} !!!</p>
          {players[0] ? (
            players[0].map((item, index) => (
              <Player key={index} content={item}/>
            ))
          ) : null}

          <p className={styles.ty}>{config.tier[0].name} ??</p>
          {players[0] ? (
            players[0].map((item, index) => (
              <Player key={index} content={item}/>
            ))
          ) : null}


  <p className={styles.ty}>Drifter Tier {index} - {config.tier[index].name}</p>
  {players[index] ? (
                players[index].map((item, subIndex) => (
                  <Player key={subIndex} content={item} />
                ))
              ) : null}


              <div key={index}>
              {levels[index] ? (
                <p className={styles.ty}>{levels[index]} Drifter Tier {levels[index] - 1} - {config.tier[levels[index]].name}</p>
              ) : (
                <p className={styles.ty}>Unknown</p>
              )}
              {players[index] ? (
                players[index].map((item, subIndex) => (
                  <Player key={subIndex} content={item} />
                ))
              ) : null}
            </div>
              */

  return (
    <div className={styles.blockDriver}>
      <div className={styles.ty2}>Online</div>
      {config.tier ? (
        <>
          {typeof players === 'object' && players !== null && !Array.isArray(players) && Object.entries(players).toReversed().map((item, index) => (
            <div key={index}>
              {players[index].length > 0 ? (
                <>
                  {levels[index] ? (
                    <p className={styles.ty}>Drifter Tier {levels[index] - 1} - {config.tier[levels[index]].name}</p>
                  ) : (
                    <p className={styles.ty}>Unknown</p>
                  )}
                  {players[index].map((item, subIndex) => (
                    <Player key={subIndex} content={item} />
                  ))}
                </>
              ) : null}
            </div>
          ))}
        </>
      ) : (null)}
    </div>
  );
}

export default Online;