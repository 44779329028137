import React, { useState, useEffect } from 'react';
import { getTrackConfig } from './TrackConfigScreenApi';
import { useNavigate } from 'react-router-dom';
import styles from './TrackConfigScreen.module.css';

function TrackConfigScreen(props) {
  const navigate = useNavigate();
  const [tracks, setTracks] = useState([]);

  document.title = "G8rage - Create Server";

  useEffect(() => {
    const fetchTrackConfig = async () => {
      try {
        const data = await getTrackConfig(props.serverTrack.id);
        props.setServerTrackConfig(data[0]);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchTrackConfig();
  }, []);

  function chooseTrack(id) {
    let key = null;
    for (const index in props.serverTrackConfig) {
      if (props.serverTrackConfig[index].id === id) {
        key = index;
        break;
      }
    }
    props.setServerTrackConfig(props.serverTrackConfig[key]);
    navigate('/create-server/step-car-pack/');
  }
  
  return (
    <>
      <div className={styles.createContent}>
        <h2>Select Track Layout</h2>
        {props.serverTrackConfig && props.serverTrackConfig.length > 0 ? (
          props.serverTrackConfig.map((item, index) => (
            <input
              key={index}
              type="button"
              value={item.name}
              onClick={() => chooseTrack(item.id)}/>
          ))) : null}
      </div>
    </>
  );
}

export default TrackConfigScreen;