import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL, apiHeader } from '../../config';

function NewOrder(props) {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  document.title = "Zdalna Pralka";

  async function getOrders() {
    try {
      const data = {
        token: props.authToken ?? props.cookie.token
      };

      const response = await axios.post(`${API_URL}user/orders`, JSON.stringify(data), apiHeader);

      if (response.status === 200) {
        setOrders(response.data);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const newLoundry = () => {
    navigate('/nowe-pranie');
  }

  useEffect(() => {
    console.log(props.cookie.token);
    console.log(props.authToken);
    if (props.cookie.token == null && props.authToken == null) {
      navigate('/login');
    }

    //getOrders();
  }, []);

  return (
    <>
      <div>
        {props.authToken || props.cookie.token ? (
          <>
            <input
              type="button"
              value="Nowe pranie"
              onClick={(e) => newLoundry()}
            />
            {orders.map((item, index) => (
              <li key={index}>a</li>
            ))}
          </>
        ) : (
          <p>Nie jesteś zalogowany.</p>
        )}
      </div>
    </>
  );
}

export default NewOrder;
