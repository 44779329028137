import styles from './loading.module.css'; 

function Footer(props) {
  return (
    <div className={styles.loading}>
      <p>{props.info}</p>
    </div>
  );
}

export default Footer;