import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAYGs39SFa7uXR60kca_GwnIQl62dNct30",
  authDomain: "tire-db-c99f7.firebaseapp.com",
  databaseURL: "https://tire-db-c99f7-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "tire-db-c99f7",
  storageBucket: "tire-db-c99f7.appspot.com",
  messagingSenderId: "164105649560",
  appId: "1:164105649560:web:a9620a0daa90f4fe95bfed",
  measurementId: "G-R356VNZ65C"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);