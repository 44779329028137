import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Login from './components/Login.js';
import SignupForm from './components/signup/SignupForm.js';
import LoginForm from './components/loginForm/LoginForm.js';
import NewOrder from './components/panel/NewOrder.js';
import CreateServer from './components/createServer/CreateServer.js';
import './global.css'; 
import base from './base.module.css';
import Navigation from './components/Navigation.js';
import MainCallToAction from './components/MainCallToAction.js';
import SetupCarPack from './components/setupCarPack/SetupCarPack.js';
import SetupCar from './components/setupCar/SetupCar.js';
import Feed from './components/feed/Feed.js';
import Online from './components/online/Online.js';
import TrackScreen from './components/createServer/TrackScreen/TrackScreen.js';
import TrackConfigScreen from './components/createServer/TrackConfigScreen/TrackConfigScreen.js';
import CarPackScreen from './components/createServer/CarPackScreen/CarPackScreen.js';
import ConfigScreen from './components/createServer/ConfigScreen/ConfigScreen.js';
import LaunchScreen from './components/createServer/LaunchScreen/LaunchScreen.js';
import NewTrackScreen from './components/createServer/NewTrackScreen/NewTrackScreen.js';

function App({ children }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {children}
    </LocalizationProvider>
  );
}

function Main() {
  const [cookie, setCookie, removeCookie] = useCookies([]);
  const [authToken, setAuthToken] = useState(null);
  const [authEmail, setAuthEmail] = useState(null);

  const [serverTrack, setServerTrack] = useState(0);
  const [serverTrackConfig, setServerTrackConfig] = useState(0);
  const [serverCarPack, setServerCarPack] = useState(0);
  const [serverName, setServerName] = useState(0);
  const routes = [
    /*{
      key: 0,
      path: '/',
      elements: [<Navigation key={0} />]
    },*/
    {
      key: 1,
      path: '/create-server/step-track/',
      elements: [
        <Navigation key={0} />,
        <TrackScreen
          key={1}
          serverTrack={serverTrack}
          serverTrackConfig={serverTrackConfig}
          setServerTrack={setServerTrack}
          setServerTrackConfig={setServerTrackConfig} />
      ]
    },
    {
      key: 2,
      path: '/create-server/step-track-layout/',
      elements: [
        <Navigation key={0} />,
        <TrackConfigScreen
          key={1}
          serverTrack={serverTrack}
          serverTrackConfig={serverTrackConfig}
          setServerTrackConfig={setServerTrackConfig} />
      ]
    },
    {
      key: 3,
      path: '/create-server/step-car-pack/',
      elements: [
        <Navigation key={0} />,
        <CarPackScreen
          key={1}
          serverCarPack={serverCarPack}
          setServerCarPack={setServerCarPack} />
      ]
    },
    {
      key: 4,
      path: '/create-server/step-config/',
      elements: [
        <Navigation key={0} />,
        <ConfigScreen
          key={1}
          serverName={serverName}
          setServerName={setServerName} />
      ]
    },
    {
      key: 5,
      path: '/create-server/step-launch/',
      elements: [
        <Navigation
          key={0}
          cookie={cookie}
          removeCookie={removeCookie}
          authEmail={authEmail}
          setAuthToken={setAuthToken}
          setAuthEmail={setAuthEmail} />,
        <LaunchScreen
          key={1}
          serverTrack={serverTrack}
          serverTrackConfig={serverTrackConfig}
          serverCarPack={serverCarPack}
          serverName={serverName} /> 
      ]
    },
    {
      key: 6,
      path: '/setup-track/',
      elements: [
        <Navigation
          key={0}
          cookie={cookie}
          removeCookie={removeCookie}
          authEmail={authEmail}
          setAuthToken={setAuthToken}
          setAuthEmail={setAuthEmail} />,
        <NewTrackScreen
          key={1}
          cookie={cookie}
        /> 
      ]
    },
    {
      key: 7,
      path: '/setup-car-pack/',
      elements: [
        <Navigation
          key={0}
          cookie={cookie}
          removeCookie={removeCookie}
          authEmail={authEmail}
          setAuthToken={setAuthToken}
          setAuthEmail={setAuthEmail} />,
        <SetupCarPack
          key={1}
          cookie={cookie}
        /> 
      ]
    },
    {
      key: 8,
      path: '/setup-car/',
      elements: [
        <Navigation
          key={0}
          cookie={cookie}
          removeCookie={removeCookie}
          authEmail={authEmail}
          setAuthToken={setAuthToken}
          setAuthEmail={setAuthEmail} />,
        <SetupCar key={1} /> 
      ]
    },
    {
      key: 9,
      path: '/feed',
      elements: [<Feed key={0} />, <Online key={1} />]
    },
    {
      key: 10,
      path: '/login',
      elements: [
        <LoginForm
          key={0}
          authToken={authToken}
          setAuthToken={setAuthToken}
          authEmail={authEmail}
          setAuthEmail={setAuthEmail}
          cookie={cookie}
          setCookie={setCookie} />
      ]
    },
    {
      key: 11,
      path: '/',
      elements: [
        <Navigation
          key={0}
          cookie={cookie}
          removeCookie={removeCookie}
          authEmail={authEmail}
          authToken={authToken}
          setAuthToken={setAuthToken}
          setAuthEmail={setAuthEmail} />
        ]
    },
    {
      key: 12,
      path: '/create-server/',
      elements: [
        <Navigation
          key={0}
          cookie={cookie}
          removeCookie={removeCookie}
          authEmail={authEmail}
          setAuthToken={setAuthToken}
          setAuthEmail={setAuthEmail} />,
        <CreateServer
          key={1}
          cookie={cookie}
          serverTrack={serverTrack}
          serverTrackConfig={serverTrackConfig}
          setServerTrack={setServerTrack}
          setServerTrackConfig={setServerTrackConfig}
          serverCarPack={serverCarPack}
          setServerCarPack={setServerCarPack}
          serverName={serverName}
          setServerName={setServerName} />
      ]
    },
    {
      key: 13,
      path: '/signup',
      elements: [
        <SignupForm
          key={0}
          authToken={authToken}
          setAuthToken={setAuthToken}
          authEmail={authEmail}
          setAuthEmail={setAuthEmail}
          cookie={cookie}
          setCookie={setCookie} />
      ]
    }
  ];

  const routeComponents = routes.map((route) => (
    <Route
      key={route.key}
      path={route.path}
      element={route.elements} />
  ));

  return (
    <div className={`${base.noselect}`}>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/">
            {routeComponents}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Main;