import React from 'react';
import styles from './modalJoin.module.css'; 

function ModalJoin(props) {
    const {isModalJoinVisible, closeModalJoin} = props;

    return (
        <>
        <div className={styles.modalJoin}  style={{ display: props.isModalJoinVisible ? 'flex' : 'none' }}>
            <div className={styles.modalJoinContainer}>
                <h3 className={styles.modalJoinConteinerTitle}>Opening AC Content Manager</h3>
                <p className={styles.modalJoinConteinerDescription}>AC Content Manager has been started on your device. If it didn't start, check whenever it is installed and properly configured.</p>
                <h4 className={styles.modalJoinConteinerSubtitle}>Download AC Content Manager</h4>
                <p className={styles.modalJoinConteinterCmlink}><a href="https://assettocorsa.club/content-manager.html" target="_blank">https://assettocorsa.club/content-manager.html</a></p>
                <input className={styles.modalJoinConteinerBtnClose} type="button" value="Close" onClick={closeModalJoin}/>
            </div>
        </div>
        </>
    );
}

export default ModalJoin;