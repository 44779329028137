import React, { useState, useEffect } from 'react';
import {
  API_URL,
  acServerApiHeader
} from '../../config';
import { getCars } from './SetupCarPackApi';
import { useNavigate } from 'react-router-dom';
import styles from './SetupCarPack.module.css';
import axios from 'axios';

function SetupCarPack(props) {
  const navigate = useNavigate();
  const [setup, setSetup] = useState([]);
  const [name, setName] = useState(null);
  const [cars, setCars] = useState([]);

  document.title = "G8rage - Create Car Pack";
  console.log(1);
  console.log(props);
  console.log(2);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const data = await getCars();
        setCars(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchCars();
  }, []);

  const setCar = (car, skin) => {
    setSetup([...setup, {car: car, skin: skin}]);
  }

  const unSetCar = (removeIndex) => {
    setSetup(
      setup.filter((_, index) => index !== removeIndex)
    );
  }

  async function saveSetup() {
    try {
      const data = {
        token: props.cookie.token,
        name: name,
        setup: setup
      };

      const response = await axios.post(`${API_URL}users/car-packs/create`, JSON.stringify(data), acServerApiHeader);
      console.log('Prepared:', data);

      if (response.status === 200) {} else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  
  return (
    <div class={styles.content}>
      <div key={0} className={styles.list}>
        <h2>Cars</h2>
        {cars.map((car, index) => (
          <div key={index}>
            <p key={index}>{car.brand} {car.model}</p>
            {car.skin.map((skin, skinIndex) => (
              <input
                key={skinIndex}
                type="button"
                value={skin.name}
                onClick={() => setCar(car, skin)}/>
              ))}
          </div>
        ))}
      </div>
      <div key={1} className={styles.createContent}>
        <h2>Setup Name</h2>
        <input
          type="text"
          defaultValue={name}
          onChange={(e) => setName(e.target.value)} />
        {setup.map((element, index) => (
          <div key={index}>
            <p>{element.car.brand} {element.car.model} {element.skin.name}</p>
            <input
              type="button"
              value="Delete"
              onClick={() => {unSetCar(index)}}/>
          </div>
        ))}
        <input
          type="button"
          value="Save Car Pack"
          onClick={() => {saveSetup()}}/>
      </div>
    </div>
  );
}

export default SetupCarPack;