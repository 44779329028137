const env = 'prod';
const title = 'G8rage';
const accmPrefix = 'A1 | 5D | A3AD | G8rage | Live Event | discord.gg/arFt6JHwzm | ';
let API_URL;
let AC_IP;
let AC_MANAGER;
let API_KEY;

if (env === 'prod') {
  API_URL = 'https://api.g8rage.com/';
  AC_IP = '46.242.130.106';
  AC_MANAGER = 'https://acmanager.g8rage.com/';
  API_KEY = '234';
} else if (env == 'test') {
  API_URL = 'http://test.g8rage.com/';
  AC_IP = '46.242.130.106';
  AC_MANAGER = 'https://acmanager.g8rage.com/';
  API_KEY = '234';
} else {
  API_URL = 'http://192.168.2.174:8000/';
  AC_IP = '46.242.130.106';
  AC_MANAGER = 'https://acmanager.g8rage.com/';
  API_KEY = '234';
}

const apiHeader = {
  headers: {
    'Authorization': API_KEY,
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  }
};

const acServerApiHeader = {
  headers: {
    'Authorization': API_KEY,
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  }
};

export {
  accmPrefix,
  title,
  API_URL,
  AC_MANAGER,
  AC_IP,
  API_KEY,
  apiHeader,
  acServerApiHeader
};