import React, { useState, useEffect } from 'react';
import styles from './title.module.css'; 

function Title(props) {
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(props.content.name);
  });

  return (
    <div className={styles.container}>
      {title ? (
        <h3 className={styles.title}>
          {title}
        </h3>
      ) : (
        <div className={styles.skelet}></div>
      )}
    </div>
  )
}

export default Title;