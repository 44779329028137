import React, { useState } from 'react';
import axios from 'axios';
import {
  API_URL,
} from '../../../config';
import styles from './NewTrackScreen.module.css';

function NewTrackScreen(props) {
  const [info, setInfo] = useState('');
  const [file, setFile] = useState(null);

  document.title = "G8rage - Create Track";

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    setInfo('Uploading...');

    const formData = new FormData();
    formData.append('token', props.cookie.token);
    formData.append('file', file);

    try {
      const response = await axios.post(`${API_URL}tracks`, formData, {
        headers: {
          'Authorization': '234',
          'Content-Type': 'multipart/form-data'
        }
      });
      setInfo(response.data.message);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  
  return (
    <>
      <div className={styles.createContent}>
        <h2>Create New Track</h2>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>
        <p>{info}</p>
      </div>
    </>
  );
}

export default NewTrackScreen;