import React from 'react';
import styles from './../block.module.css'; 

function FeedBlockJoinButton({ toggleModalJoin, content }) {
    return (
        <div className={styles.serverBtnJoinContainer}>
            {content ? (
                <h3 className={styles.serverTitleH3}>
                    <a><input className={styles.btnJoin} type="button" value="Join" onClick={() => toggleModalJoin(true)}/></a>
                </h3>
            ) : (
                <div className={styles.serverBtnJoinSkelet}></div>
            )}
        </div>
    );
}

export default FeedBlockJoinButton;