import React, { useState, useEffect } from 'react';
import { getCarPacks } from './CarPackScreenApi';
import { useNavigate } from 'react-router-dom';
import styles from './CarPackScreen.module.css';

function CarPack(props) {
  const navigate = useNavigate();  
  const [carPack, setCarPack] = useState(0);
  const [carPacks, setCarPacks] = useState([]);
  const [selectedCarPack, setSelectedCarPack] = useState(0);

  document.title = "G8rage - Create Server";

  useEffect(() => {
    const fetchCarPacks = async () => {
      try {
        const data = await getCarPacks();
        setCarPacks(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchCarPacks();
  }, []);

  function chooseCarPack(id) {
    let key = null;
    for (const index in carPacks) {
      if (carPacks[index].id === id) {
        key = index;
        break;
      }
    }
    props.setServerCarPack(carPacks[key]);
    navigate('/create-server/step-config/');
  }
  
  return (
    <>
      <h1 className={styles.stepTitle}>Select Car Pack</h1>
      <div className={styles.gridContainer}>
        {carPacks.map((item, index) => (
          <div key={index} className={styles.carPackContainer}>
            <h3 className={styles.title}>
              {item.name}
            </h3>
            {item.entry.map((item, itemIndex) => (
              <div key={itemIndex} className={styles.carPackGridContainer}>
                <div>{item.grid + 1}</div>
                <div>{item.car}</div>
                <div>{item.skin}</div>
              </div>
            ))}
            <input
              key={index}
              className={`${
                selectedCarPack === item.id ? styles.active : null
              } ${styles.btnJoin}`}
              type="button"
              value="Select"
              onClick={() => chooseCarPack(item.id)}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default CarPack;