import React, { useEffect, useState } from 'react';
import { acLauncher } from '../../../acLauncher';
import ModalJoin from './../ModalJoin/ModalJoin.js';
import ModalDownload from './../ModalDownload/ModalDownload.js';
import FeedBlockJoinButton from './joinButton/JoinButton.js';
import FeedBlockDownloadButton from './downloadButton/DownloadButton.js';
import Title from './title/Title.js';
import NameSlashSeparator from './nameSlashSeparator/NameSlashSeparator.js';
import Track from './track/Track.js';
import CarPack from './carPack/CarPack.js';
import styles from './block.module.css'; 

function Block(props) {
  const [isModalDownloadVisible, setIsModalDownloadVisible] = useState(false);
  const [isModalJoinVisible, setIsModalJoinVisible] = useState(false);
  const [blockType, setBlockType] = useState('');

  const toggleModalDownload = () => {
    setIsModalDownloadVisible(!isModalDownloadVisible);
  };

  const toggleModalJoin = (launch = false) => {
    setIsModalJoinVisible(!isModalJoinVisible);
    if (launch === true) {
      acLauncher(props.content.port);
    }
  };

  useEffect(() => {
    setBlockType(props.content.type);
  });

  return (
    <>
      <ModalDownload
        isModalDownloadVisible={isModalDownloadVisible}
        closeModalDownload={toggleModalDownload}
        content={props.content}/>
      <ModalJoin
        isModalJoinVisible={isModalJoinVisible}
        closeModalJoin={toggleModalJoin}/>
      <div
        className={styles.serverContainer}>
        <div
          className={styles.serverHeaderContainer}>
          <div
            className={styles.serverBtnOnlineContainer}>
            <a
              className={styles.onlineLink}>
              <div
                className={styles.btnOnline}></div>
            </a>
            {/*<div
              className={styles.btnOnlineDescription}>
              Server is offline.
            </div>*/}
          </div>
          <Title content={props.content}/>
          <div
            className={styles.serverAuthorContainer}>
          </div>
          <div></div>
          {blockType === 'server' ? (
            <>
              <div className={styles.relative}>
                <FeedBlockDownloadButton
                  toggleModalDownload={toggleModalDownload}/>
              </div>
              <div></div>
              <div className={styles.relative}>
                <FeedBlockJoinButton
                  content={props.content}
                  toggleModalJoin={toggleModalJoin}/>
              </div>
            </>
          ) : (null)}
          {blockType === 'track' || blockType === 'carPack' ? (
            <>
              <div></div>
              <div></div>
              <div className={styles.relative}>
              <FeedBlockDownloadButton
                toggleModalDownload={toggleModalDownload}/>
              </div>
            </>
          ) : (null)}
        </div>
        <div
          className={styles.serverDescriptionContainer}>
          <div></div>
          <div>
            {blockType === 'server' ? (
              <>
                <Track content={props.content.track}/>
                <NameSlashSeparator />
                <CarPack content={props.content.carPack}/>
              </>
            ) : (
              null
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Block;